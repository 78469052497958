import React, { Component } from 'react';
import { SettingsContext } from "../../../../Magazine/MagazineSettings";
import BlockChapitre from "./BlockChapitre";
import Loadable from 'react-loadable';
import Text from "./components/Text";
import Title from "./components/Title";
import Citation from "./components/Citation";
import Carrousel from "./components/Carrousel";
import Image from "./components/Image";
import Chiffres from "./components/Chiffres";

import "./style/slick.scss";
import "./style/lightbox.scss";

import "../style/components.scss";
import classNames from "classnames";
import Breve from "./components/Breve";

// import "slick-carousel/slick/slick.scss"
// import "slick-carousel/slick/slick-theme.scss";
// import 'react-image-lightbox/style.css';

let Masonry;

const RightSidebarComponent = Loadable({
    loader: () => import('./RightSidebar'),
    loading: () => { return null },
});

const masonryOptions = {
    transitionDuration: 0
};

export class Content extends Component {

    constructor(props) {
        super(props);

        this.state = {
            masonryReady: false,
        }
    }

    initMasonry() {
        if(!this.props.isPage && this.props.article.custom.is_breves) {
            if(!this.state.masonryReady) {
                import('react-masonry-component').then((module) => {
                    Masonry = module.default;
                    this.setState({
                        masonryReady: true
                    });
                });
            }
        }
    }

    componentDidMount() {
        this.initMasonry();
    }

    componentDidUpdate() {
        this.initMasonry();
    }

    childElements(article, fonts) {

        if(article.content) {
            let childs = article.content.map((content, index) => {

                switch (content.kind) {
                    case 'title':
                        return (<Title datas={content}
                                       fonts={fonts} key={content.id} article={ article } />);
                    case 'text':
                        return (<Text datas={content}
                                      fonts={fonts} key={content.id} article={ article } />);
                    case 'citation':
                        return (<Citation datas={content}
                                          fonts={fonts} key={content.id} article={ article } />);
                    case 'slider':
                        return (<Carrousel datas={content}
                                           fonts={fonts} key={content.id} article={ article } />);
                    case 'images':
                        return (<Image datas={content}
                                       fonts={fonts} key={content.id} article={ article } />);
                    case 'chiffres':
                        return (<Chiffres datas={content}
                                          fonts={fonts} key={content.id} article={ article } />);
                    case 'breve':
                        return (
                            <Breve datas={content}
                                   fonts={fonts} key={content.id} article={ article } />
                        );
                    default:
                        return null;
                }
            });
            return childs;
        }

        return null;

    }

    render() { const { article, match, isPage } = this.props; let self = this;

        const classes = classNames({
            'breves': (article.custom) ? article.custom.is_breves: false,
            'content': true
        });

        //let classesAuthor;

        let classesAuthor = classNames({
            'author-block': true,
            'isNotImg': (article.author) ? !article.author.image : false
        });

        let classesGlobal = classNames({
            'global-content': true,
            'noDesc': !article.description
        });

        return(
            <SettingsContext.Consumer>
                {({ fonts }) => (
                    <div className={ classes } style={ fonts.family4 }>

                        {!isPage && !article.custom.is_breves &&
                            <div className="left-bloc">
                                <BlockChapitre match={ match } fonts={ fonts } />
                            </div>
                        }

                        <div className="right-bloc">
                            {article.description &&
                            <div className="description main-description" style={ fonts.family4 }>
                                <div data-text="true" dangerouslySetInnerHTML={{ __html: article.description }} />
                            </div>
                            }
                            <div className="content-right">
                                <div className={ classesGlobal }>
                                    <div data-text="true">

                                        {self.childElements &&
                                        <ContentInner isPage={isPage}
                                                      article={article}
                                                      childElements={self.childElements}
                                                      fonts={fonts}
                                                      masonryReady={this.state.masonryReady}/>
                                        }

                                        {article.author && article.author.first_name && article.author.last_name &&
                                            <div className={ classesAuthor }>
                                                <div className="inner">
                                                    <div className="avatar">
                                                        {article.author.image &&
                                                            <img src={article.author.image} alt=""/>
                                                        }
                                                    </div>
                                                <div className="informations">
                                                    <span className="name" style={ fonts.family1 }>
                                                        {article.author.first_name} {article.author.last_name}
                                                    </span>
                                                    <div style={ fonts.family4 }>
                                                        {article.author.metier &&
                                                            <span className="metier">
                                                                <i className="icon-avatar_author" />
                                                                { article.author.metier }
                                                            </span>
                                                        }
                                                        {article.author.email &&
                                                            <a href={'mailto:' + article.author.email} className="email">
                                                                <i className="icon-mail_author" /><span>{article.author.email}</span>
                                                            </a>
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {!isPage && !article.custom.is_breves &&
                                    <div className="sidebar-outer">
                                        {/*<RightSidebarComponent article={ article }/>*/}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )}
            </SettingsContext.Consumer>
        )
    }

}

const ContentInner = ({ childElements, isPage, article, fonts, masonryReady }) => {

    if(isPage || !article.custom.is_breves) {
        return childElements(article, fonts);
    } else if(article.content && masonryReady) {
        return(
            <Masonry
                className={'my-gallery-class'} // default ''
                elementType={'ul'} // default 'div'
                options={ masonryOptions } // default {}
                disableImagesLoaded={ false } // default false
                updateOnEachImageLoad={ false } // default false and works only if
            >
                { childElements(article, fonts) }
            </Masonry>
        )
    }

    return null;

};

import React, { Component } from 'react';
import { VERIFY_FORM, SEND_FORM } from "../../../../../Queries";
import classNames from "classnames";

export class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: {
                firstname: {
                    value: "",
                    alreadyEdit: false,
                    required: true
                },
                lastname: {
                    value: "",
                    alreadyEdit: false,
                    required: true
                },
                email: {
                    value: "",
                    alreadyEdit: false,
                    required: true
                },
                message: {
                    value: "",
                    alreadyEdit: false,
                    required: true
                },
            },
            isSubmit: false,
            focus: "",
            form: {
                message :"",
                status: "",
                errors: []
            }
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) { let self = this;
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            fields: {
                ...this.state.fields,
                [name]: {
                    ...this.state.fields[name],
                    value: value,
                    alreadyEdit: (!this.state.fields[name].alreadyEdit) ? !!(value) : true
                }
            }
        }, () => {
            let verifyForm = this.props.client.query(
                {
                    query: VERIFY_FORM,
                    fetchPolicy: 'network-only',
                    variables: {
                        magazine_slug: this.props.magazine_slug,
                        infos: {
                            fields: this.state.fields,
                            isSubmit: this.state.isSubmit
                        }
                    }
                });
            verifyForm.then((res) => {

                self.setState({
                    form: res.data.verifyContactForm
                });
            });
        });

    }

    isError = (name) => {
        if(this.state.form.errors)
            return this.state.form.errors.some((error) =>
                (error.name === name)
            );
        return false;
    };

    isFocus = (name) => {
        return (name === this.state.focus);
    };

    errorInput = (name) => {
        if(this.state.form.errors)
            return this.state.form.errors.map((error, index) =>
                {
                    if(error.name === name){
                        return <span className="err" key={ index }>{ error.error }</span>
                    }
                }
            );
        return null;
    };

    handleFocus = (name) => {
        this.setState({
            focus: name,
        })
    };

    handleSubmit = (event) => { let self  = this;
        event.preventDefault();

        let sendForm = this.props.client.query(
            {
                query: SEND_FORM,
                fetchPolicy: 'network-only',
                variables: {
                    magazine_slug: this.props.magazine_slug,
                    infos: {
                        fields: this.state.fields,
                        isSubmit: true
                    }
                }
            });

        sendForm.then((res) => {

            self.setState({
                ...this.state,
                form: {
                    ...this.state.form,
                    errors: res.data.sendContactForm.errors.errors,
                    message: res.data.sendContactForm.message,
                    status: res.data.sendContactForm.status
                },
                isSubmit: true
            }, () => {
            })
        });

    };

    render() {

        return (
            <div className="form-outer">
                <form onSubmit={ this.handleSubmit } style={ this.props.fonts.family3 }>

                    <div className={ classNames({
                        inputBloc: true,
                        error: this.isError('firstname'),
                        isFocus: this.isFocus('firstname'),
                        isEmpty: !this.state.fields.firstname.value
                    }) }>
                        <label>Prénom</label>
                        <input type="text"
                               name="firstname"
                               value={ this.state.fields.firstname.value }
                               onChange={ this.handleInputChange }
                               onFocus={ () => { this.handleFocus('firstname') } }
                        />
                    </div>
                    { this.errorInput('firstname') }

                    <div className={ classNames({
                        inputBloc: true,
                        error: this.isError('lastname'),
                        isFocus: this.isFocus('lastname'),
                        isEmpty: !this.state.fields.lastname.value
                    }) }>
                        <label>Nom</label>
                        <input type="text"
                               name="lastname"
                               value={ this.state.fields.lastname.value }
                               onChange={ this.handleInputChange }
                               onFocus={ () => { this.handleFocus('lastname') } }
                        />
                    </div>
                    { this.errorInput('lastname') }

                    <div className={ classNames({
                        inputBloc: true,
                        error: this.isError('email'),
                        isFocus: this.isFocus('email'),
                        isEmpty: !this.state.fields.email.value
                    }) }>
                        <label>Email</label>
                        <input type="email"
                               name="email"
                               value={ this.state.fields.email.value }
                               onChange={ this.handleInputChange }
                               onFocus={ () => { this.handleFocus('email') } }
                        />
                    </div>
                    { this.errorInput('email') }

                    <div className={ classNames({
                        inputBloc: true,
                        error: this.isError('message'),
                        isFocus: this.isFocus('message'),
                        isEmpty: !this.state.fields.message.value
                    }) }>
                        <label>Message</label>
                        <textarea
                            name="message"
                            value={ this.state.fields.message.value }
                            onChange={ this.handleInputChange }
                            onFocus={ () => { this.handleFocus('message') } }
                        />
                    </div>
                    { this.errorInput('message') }

                    <input type="submit" value="Envoyer le message" style={ this.props.fonts.family1 } />
                    {this.state.form.message && !this.state.form.status &&
                        <div className="form-message error">
                            { this.state.form.message }
                        </div>
                    }
                    {this.state.form.message && this.state.form.status &&
                    <div className="form-message ok">
                        { this.state.form.message }
                    </div>
                    }
                </form>
            </div>
        );
    }
}

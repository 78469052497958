// import clip from "text-clipper";
import { Component } from "react";
import * as React from "react";

import animations from "./animations";
import "./style/search.scss";
import {GET_ALL_ARTICLES_WITH_CONTENT, SEARCH_FORM} from "../../../../Queries";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import classNames from "classnames";

import spinner from "./assets/rolling.gif";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

let timeout = null;

const makeCancelable = (promise) => {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then((val) =>
            hasCanceled_ ? reject({isCanceled: true}) : resolve(val)
        );
        promise.catch((error) =>
            hasCanceled_ ? reject({isCanceled: true}) : reject(error)
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
};

export class SearchView extends Component {

    constructor(props){
        super(props);

        this.state = {
            render: false,
            show: false,
            searchValue: "",
            searchNumber: 0,
            searchInternal: [],
            searchExternal: [],
            animOn: null,
            animOff: null,
            isSearching: false,
            found_posts: 0,
            heightSearch: null,
            searchExternalTimeOut: null,
            searchInternalTimeOut: null,
            offset: 0,
            cancel: false,
            active: false
        };

        this.queryLevel1 = null;
        this.queryLevel2 = null;
        this.queryLevel3 = null;

    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.search.isSearching !== prevState.show)
            return {
                show: nextProps.search.isSearching,
                isSearching: false
            };

        return null;
    }

    initState = () => {
        clearTimeout(this.state.searchExternalTimeOut);
        clearTimeout(this.state.searchInternalTimeOut);
        clearTimeout(this.state.animOn);
        clearTimeout(this.state.animOff);

        this.cancelQueries();
    };

    cancelQueries = () => {

        if(this.queryLevel1) {
            this.queryLevel1.cancel();
            this.queryLevel1 = null;
        }

        if(this.queryLevel2) {
            this.queryLevel2.cancel();
            this.queryLevel2 = null;
        }

        if(this.queryLevel3) {
            this.queryLevel3.cancel();
            this.queryLevel3 = null;
        }

    };

    componentWillUnmount() {
        this.initState();
    }

    animationMenu(show) {

        if(show){

            this.setState({
                render: !this.state.render ,
                animOn: setTimeout(() => {
                    animations.onEnter(this.ref);
                }, 10)
            });

        } else {

            animations.onLeave(this.ref);

            this.setState({
                animOff: setTimeout(() => {
                    this.setState({
                        render: !this.state.render,
                    });
                }, 10)
            });

        }
    }

    setReference = (ref) => {
        this.ref = ref;
    };

    async setHeightCouverture() {

        if(typeof window !== "undefined") {
            let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

            if (iOS) {
                let heightCouverture = await import('ios-inner-height');
                let newHeight = heightCouverture.default() + "px";

                this.setState({
                    heightSearch: window.innerHeight
                });

                return true;
            }
        }

    }

    componentDidMount(){

        this.queryLevel1 = null;
        this.queryLevel2 = null;
        this.queryLevel3 = null;

        this.setHeightCouverture().then(() => {});

        this.setState({
            show: this.props.search.isSearching
        });

    }

    componentDidUpdate(prevProps, prevState) { let self = this;

        if(prevProps.search.isSearching !== this.state.show){

            if(this.state.show) {
                disableBodyScroll(this.searchViewContainer);
                setTimeout(function(){
                    self.Input.focus();
                }, 20)
            } else {
                enableBodyScroll(this.searchViewContainer);
            }

            //this.initState();
            this.cancelQueries();

            this.animationMenu(this.state.show);
            this.setHeightCouverture().then(() => {});

            if(typeof document.getElementsByClassName("search-view")[0].scrollTo !== "undefined"){
                document.getElementsByClassName("search-view")[0].scrollTo(0, 0);
            } else {
                document.getElementsByClassName("search-view")[0].scrollTop = 0;
            }
        }

    }

    searchChangeValue = (event, client) => {
        this.setState({
            searchValue: event.target.value
        });
    };

    isRemoveKey = (event, client) => { let self = this;

        clearTimeout(this.state.searchExternalTimeOut);
        clearTimeout(this.state.searchInternalTimeOut);

        self.setState({
            active: false
        });

    };

    searchFilterNumeros = (event, client) => { let self = this;

        //clearTimeout(this.state.searchInternalTimeOut);
        //clearTimeout(this.state.searchExternalTimeOut);

        if(event.target.value !== "") {

            let timeoutV = 400;

            if(event.target.value.length > 1){
                timeoutV = 200;
            }

            this.setState({
                searchInternalTimeOut : setTimeout(function () {

                    self.queryLevel1 = makeCancelable(client.query({query: GET_ALL_ARTICLES_WITH_CONTENT, fetchPolicy: 'network-only'}));

                    self.queryLevel1.promise.then((article) => {
                        self.setState({
                            active: true
                        });
                    });

                    self.searchTransversal();

                }, timeoutV)
            });

        } else {
            //clearTimeout(this.state.searchExternalTimeOut);
            //clearTimeout(this.state.searchInternalTimeOut);

            setTimeout(function() {
                self.setState({
                    active: false
                });
            }, 700);
        }

    };

    searchTransversal = (input, promiseChild = false) => { let self = this;
        let stateObj;

        if (this.state.cancel && promiseChild) {
            return;
        }

        if (this.state.cancel && !promiseChild) {
            clearTimeout(this.state.searchExternalTimeOut);
            stateObj = {
                cancel: false, offset: 0, isSearching: true, searchExternal: []
            }
        }

        if (!this.state.cancel && !promiseChild) {
            clearTimeout(this.state.searchExternalTimeOut);
            stateObj = {
                cancel: false, offset: 0, isSearching: true, searchExternal: []
            }
        }

        if (!this.state.cancel || !promiseChild) {
            this.setState(stateObj, () => {

                self.queryLevel3 = makeCancelable(this.props.client.query(
                    {
                        query: SEARCH_FORM,
                        ssr: false,
                        variables: {
                            magazine_slug: this.props.magazine_slug,
                            input: this.state.searchValue,
                            offset: this.state.offset
                        }
                    }));

                self.queryLevel3.promise.then((article) => {

                    if (article.data.searchArticles) {

                        self.setState({
                            searchExternal: this.state.searchExternal.concat(article.data.searchArticles.articles),
                            offset: (article.data.searchArticles.found_posts > 0) ? this.state.offset + 15 : 0,
                        }, () => {

                            self.queryLevel2 = makeCancelable(this.props.client.query(
                                {query: GET_ALL_ARTICLES_WITH_CONTENT, fetchPolicy: 'network-only'}));

                            self.queryLevel2.promise.then((article) => {});

                            if (article.data.searchArticles.found_posts > 0) {

                                this.setState({
                                    searchExternalTimeOut : setTimeout(function() {
                                        self.searchTransversal(input, true);
                                    }, 20)
                                });

                            }
                        });

                    } else {
                        this.setState({
                            isSearching: false
                        })
                    }

                });

            });

        }

    };

    renderContentSurlignage(content, exp) {

        let occurence = 0;
        let regEx = new RegExp("(" + exp + ")(?!([^<]+)?>)", "gi");

        if(!content) return content;

        let replace = content.replace(regEx, function(match, p1, offset, input_string)
            {
                occurence++;
                return "<span class='surlign'>" + p1 + "</span>";
            }
        );

        if(occurence === 0) {
            return content;
        }

        return replace;
    }

    filterArticle(article) {

        let checkDescription = "";
        let checkTitle = article.title.toLowerCase().includes(this.state.searchValue.toLowerCase());

        if(article.description) {
            checkDescription = article.description.toLowerCase().includes(this.state.searchValue.toLowerCase());
        }

        let content = article.content.json;
        let checkContent = false;

        if(content) {

            checkContent = content.filter(obj => {
                return (obj.kind === "text" || obj.kind === "title")
            }).filter((content) => {
                return content.text.toLowerCase().includes(this.state.searchValue.toLowerCase())
            });

            checkContent = checkContent.length > 0;

        }

        return checkTitle || checkDescription || checkContent;

    }

    goTo = (e, link, numero) => { let self = this;
        e.preventDefault();

        this.props.toggleLoadingOn();

        if(this.props.numero._id !== numero._id) {
            this.props.toggleMenu(false, numero, false);
        } else {
            this.props.toggleSearch();
        }

        this.props.history.push(link);

        // self.props.history.push(link);

        /*this.props.client.resetStore().then(data=> {
            this.props.history.push(link);
        });*/

    };

    renderFilter(data) {

        let articlesArr = [];

        let articles = data.filter((article) =>
            {
                return this.filterArticle(article)
            }
        );


        if(this.state.searchNumber !== articles.length)
            this.setState({
                searchNumber: articles.length
            });

        return articles;
    }

    filterLocalAndSearch = (local, searchExternal) => {

        let filterLocal = this.renderFilter(local);
        let concat = filterLocal.concat(searchExternal);

        return concat.filter((article, index, self) =>
            index === self.findIndex((t) => (
                t.slug === article.slug
            ))
        );

    };

    getLinkedNumeroLocally = (numeroId) => {
        return this.props.data.numeros.find((numero) => numero._id === numeroId)
    };

    render() {

        if(typeof window !== "undefined") {
            if (this.state.render) {
                requestAnimationFrame(() => {
                    document.body.style.overflowY = "hidden";
                });
            } else {
                requestAnimationFrame(() => {
                    document.body.style.overflowY = "initial";
                });
            }
        }

        const classesView = classNames({
            'toggled': this.state.render,
            'search-view': true
        });

        const classes = classNames({
            'start': !this.state.active && this.state.searchValue === "",
            'searchBox': true
        });

        const classesNumber = classNames({
            'active': this.state.active && this.state.searchValue !== "",
            'results-number': true
        });


        return (
            <Query query={ GET_ALL_ARTICLES_WITH_CONTENT } fetchPolicy={'cache-only'}>
                {({loading, error, data, client}) => {

                    let concatArticles = [];

                    if(data.getArticles) {
                        concatArticles = this.filterLocalAndSearch(data.getArticles, this.state.searchExternal);
                    }

                    return (
                        <div className={ classesView } style={{ height: this.state.heightSearch }}
                             ref={(ref) => this.searchViewContainer = ref }>
                            <div className="inner" ref={(ref) => this.setReference(ref) }>
                                <div className={ classes }>
                                    <div className="inner">
                                        <span className={ classesNumber } style={ this.props.fonts.family1 }>
                                            {this.state.isSearching && <span className="spinner">
                                                <img src={ spinner } alt=""/>
                                            </span>
                                            }
                                            <span className="number">
                                                {concatArticles.length}
                                            </span>
                                            <span> résultats de recherche pour : </span>
                                        </span>
                                        <input type="text" placeholder={"je recherche..."} style={ this.props.fonts.family1 } value={ this.state.searchValue} ref={(input) => { this.Input = input; }}
                                               onChange={(e) => this.searchChangeValue(e, client)}
                                               onKeyDown={(e) => this.isRemoveKey(e, client) }
                                               onKeyUp={(e) => this.searchFilterNumeros(e, client) }/>
                                    </div>
                                </div>
                                <div className="searchResults">
                                    <div className="inner">
                                        {data.getArticles
                                         && this.state.active
                                         && this.state.searchValue !== ""
                                         && concatArticles.map((article) => {

                                            return <ArticleSearchRender
                                                key={ article.slug }
                                                article={ article }
                                                searchValue={ this.state.searchValue }
                                                numeroProps={ this.props.numero }
                                                fonts={ this.props.fonts }
                                                goTo={ this.goTo }
                                                getLinkedNumeroLocally={ this.getLinkedNumeroLocally }
                                                renderContentSurlignage={ this.renderContentSurlignage }
                                                />
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Query>
        )

    }

}

let ArticleSearchRender = ({ article, numeroProps, fonts, searchValue, renderContentSurlignage, goTo, getLinkedNumeroLocally }) => {

    let numero = numeroProps;

    if(article.numeroObj) {
        numero = article.numeroObj;
    } else {
        numero = getLinkedNumeroLocally(article.numero);
    }

    return (
        <div className="article-result">
            <Link onClick={(e) => goTo(e, `/${numero.slug}/${article.category.slug}/${article.slug}`, numero ) } to={ `/${numero.slug}/${article.category.slug}/${article.slug}` }>
                <div className="numero" style={ fonts.family4 }>{numero.title}</div>
                <div className="title" style={ fonts.family2 }>{article.title}</div>
                <div className="description" style={ fonts.family4 }
                     dangerouslySetInnerHTML={ { __html: renderContentSurlignage(article.description, searchValue) } } />
            </Link>
        </div>
    )


};

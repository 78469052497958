import React, { Component } from 'react';
import { connect } from "react-redux";
import { GET_CURRENT_NUMERO } from "../../../../../Queries";
import { Query } from "react-apollo";
import classNames from 'classnames';
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { ScrollContext } from "../../../../Scroll/Scroll";

class BlockChapitre extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fixed: false,
            scrolled: false
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.isCurrent = this.isCurrent.bind(this);

    }

    findArticlesToChapitre(articles) {
        return articles.filter((article) => article.category.slug === this.props.match.params.chapitre);
    }

    isCurrent(article) {
        return (article.slug === this.props.match.params.slug)
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll, false);
    }

    handleScroll() {
        if( window.scrollY >= (539 - 90)){
            if(!this.state.scrolled)
                this.setState({ fixed: true, scrolled: true })
        } else {
            if(this.state.scrolled)
                this.setState({ fixed: false, scrolled: false })
        }
    }

    render() {

        return(
            <Query query={ GET_CURRENT_NUMERO }>
                {({loading, error, data, client}) => {
                    const magazine = this.context.magazine;
                    const articles = data.currentNumero.articles;

                    let articlesInChapitre = this.findArticlesToChapitre(articles);

                    if(articlesInChapitre)
                        return (
                            <ScrollContext.Consumer>
                                {({ scrollWidth, scrollValue }) => (
                                    <BlockChapitreView
                                        articles={ articlesInChapitre }
                                        numero={ data.currentNumero }
                                        user={ this.props.user }
                                        scrollValue={ scrollValue }
                                        scrollWidth={ scrollWidth }
                                        fixed={ this.state.fixed }
                                        isCurrent={ this.isCurrent }
                                        fonts={ this.props.fonts }
                                    />
                                )}
                            </ScrollContext.Consumer>
                        );
                    else return null;

                }}
            </Query>
        );

    }

}

const mapStateToProps = (state, props) => {
    return {
        user: state.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

BlockChapitre.contextTypes = {
    magazine: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BlockChapitre));

const isRead = (user, article) => {
    if(user.articles) {
        return user.articles.some((articleObj) => {
            return (articleObj._id === article._id && articleObj.readed);
        });
    } return false;
};

class BlockChapitreView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fixed: false,
            scrolled: false,
            top: "initial",
            height: "auto",
        };

        //this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        //window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount(){
        //window.removeEventListener('scroll', this.handleScroll, false);
    }

    handleScroll() {

        if(document.getElementsByClassName("frame").length > 0) {
            let heightFrame = document.getElementsByClassName("cible-view")[0].getBoundingClientRect().height;
            let heightHeader = document.querySelector("header.header").getBoundingClientRect().height;
            let playerHeight = 0;
            let titleChapitreHeight = 0;
            let paddingTopChapitre = "0px";

            if (document.querySelector(".block-chapitre")) {
                titleChapitreHeight =
                    document.querySelector(".inner-chapitre > header").getBoundingClientRect().height;
                paddingTopChapitre = window.getComputedStyle(document.querySelector(".inner-chapitre"), null).getPropertyValue("padding-top");
                playerHeight = document.querySelector(".player-outer").getBoundingClientRect().height;
            }

            if (this.props.scrollValue >= heightFrame - heightHeader) {
                if (!this.state.scrolled) {
                    this.setState(
                        {
                            fixed: true,
                            scrolled: true,
                            top: heightHeader + "px",
                            height: (window.innerHeight - heightHeader - titleChapitreHeight - playerHeight - parseInt(paddingTopChapitre, 10)) + "px"
                        })
                }
            } else {
                if (this.state.scrolled)
                    this.setState(
                        {
                            fixed: false,
                            scrolled: false,
                            top: "initial",
                            height: "auto"
                        })
            }
        }

    }

    render() {

        if(typeof window !== "undefined") {
            this.handleScroll();
        }

        const { articles, numero, fixed, isCurrent, fonts, user, scrollWidth } = this.props;

        const classes = classNames({
            'fixed': this.state.fixed,
            'inner-chapitre': true,
        });

        return(
            <section className="block-chapitre">
                <div className={ classes } style={ { top: this.state.top } }>
                    <header>
                        <h1 data-text="true" style={ fonts.family3 }>Progression dans le chapitre</h1>
                    </header>
                    <nav style={ fonts.family3 }>
                        <ul style={ { height: this.state.height } }>
                            {articles.map((article, index) => {

                                const isReadArticle = isRead(user, article);
                                const isCurrentArticle = isCurrent(article);

                                const classes = classNames({
                                    'current': isCurrentArticle,
                                    'readed': !isReadArticle
                                });

                                const iconClasses = classNames({
                                    'done': !isReadArticle,
                                    'icon-status_done': true
                                });

                                return(
                                    <li key={index} className={ classes } data-text="true">
                                        <Link to={`/${numero.slug}/${article.category.slug}/${article.slug}`} style={ fonts.family3 }>
                                            { isCurrentArticle &&
                                            <ScrollVisualizer scrollWidth={ scrollWidth } />
                                            }
                                            <i className={ iconClasses } />
                                            <span>{article.title}</span>
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </nav>
                </div>
            </section>
        )

    }
}


class ScrollVisualizer extends Component {

    render() {

        const style = {
            width: this.props.scrollWidth + "%"
        };

        return(
            <div className="scroll-visualiser" style={ style }  ref={ this.ref } />
        )
    }

}

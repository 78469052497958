import { Component } from "react";
import * as React from "react";

let ChiffreSlider;

class Chiffres extends Component {

    constructor(props){
        super(props);
        this.state = {
           isLoaded: false, heightSlides: 0
        }
    }

    componentDidMount() { let self = this;
        import('react-slick').then((module) => {
            ChiffreSlider = module.default;
            this.setState({
                isLoaded: true
            });
            setTimeout(function() {

                let heights = [];

                document.querySelectorAll('.content-chiffre .inner').forEach((chiffre) => {
                    heights.push(chiffre.offsetHeight)
                });

                self.setState({
                    heightSlides: Math.max(...heights),
                });


            }, 200)
        });
    }

    render() { let self = this;
        let { fonts, datas } = this.props;

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            adaptiveHeight:true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
        };

        /*const settings = {
            dots: false,
            infinite: true,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            className: "center",
            centerPadding: "100px",
            speed: 200,
            draggable: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        centerPadding: "80px",
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        centerPadding: "30px",
                        draggable: true
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        centerPadding: "30px",
                        draggable: true
                    }
                }
            ]
        };*/

        // return (
        //     <div className="component component-chiffres">
        //         <ul>
        //             {datas.chiffres.map((chiffre, index) => (
        //                 <li className="content-chiffre" key={ index }>
        //                     { chiffre.chiffre } { chiffre.legende }
        //                 </li>
        //             ))}
        //         </ul>
        //     </div>
        // );

        let article = this.props.article;

        let style = {};
        style.backgroundColor = article.category.couleur;

        if(self.state.heightSlides > 0) {
            style.height = self.state.heightSlides;
        }

        if(this.state.isLoaded) {
            return (
                <div className="component component-chiffres">
                    {datas.chiffres.length > 1 &&
                        <button className="prev-slider" onClick={(e) => this.slider.slickPrev() }>
                            <i className="ico-cr-chevron-left" />
                        </button>
                    }
                    <ChiffreSlider {...settings} ref={ slider => (this.slider = slider)} >
                        {datas.chiffres.map((chiffre, index) => (
                            <div className="content-chiffre" key={ index }>
                                <div className="inner" style={ style }>
                                    <div className="chiffre" style={ fonts.family1 }>
                                        { chiffre.chiffre }
                                    </div>
                                    <div className="legende" style={ fonts.family4 }>
                                        { chiffre.legende }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </ChiffreSlider>
                    {datas.chiffres.length > 1 &&
                        <button className="next-slider" onClick={(e) => this.slider.slickNext()}>
                            <i className="ico-cr-chevron-right"/>
                        </button>
                    }
                </div>
            );
        } else {
            return (
                <div className="component component-chiffres">
                    {datas.chiffres.map((chiffre, index) => (
                        <div className="content-chiffre" key={ index } style={{ width: 100 }}>
                            <div className="chiffre" style={ fonts.family1 }>
                                { chiffre.chiffre }
                            </div>
                            <div className="legende" style={ fonts.family4 }>
                                { chiffre.legende }
                            </div>
                        </div>
                    ))}
                </div>
            );
        }

    }

}

export default Chiffres;

import * as React from "react";
import { Component } from "react";
import ImageComponent from "./ImageComponent";

let Slider;

class CenterMode extends Component {

    constructor(props){
        super(props);
        this.state = {
            isDragging: false,
            isLoaded: false
        }
    }

    componentDidMount() {
        import('react-slick').then((module) => {
            Slider = module.default;
            this.setState({
                isLoaded: true
            });
        });
    }

    toggleDragging = () => this.setState({isDragging: !this.state.isDragging});

    render() {

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false
        };

        /*const settings = {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "100px",
            slidesToShow: 1,
            speed: 200,
            draggable: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        centerPadding: "80px",
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        centerPadding: "30px",
                        draggable: true
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        centerPadding: "30px",
                        draggable: true
                    }
                }
            ]
        };*/

        if(this.state.isLoaded) {

            return (
                <div>
                    <button className="prev-slider" onClick={(e) => this.slider.slickPrev() }>
                        <i className="ico-cr-chevron-left" />
                    </button>
                    <Slider {...settings} toggleDragging={ this.toggleDragging }
                            ref={ slider => (this.slider = slider)}>
                        {this.props.datas.images.map((image, index) => (
                            <div key={ index }>
                                <ImageComponent index={ index } images={ this.props.datas.images } isDragging={ this.state.isDragging } fonts={ this.props.fonts } />
                            </div>
                        ))}
                    </Slider>
                    <button className="next-slider" onClick={(e) => this.slider.slickNext() }>
                        <i className="ico-cr-chevron-right" />
                    </button>
                </div>
            );
        } else {
            return (
                <div>
                    {this.props.datas.images.map((image, index) => (
                        <div key={ index }>
                            <ImageComponent index={ index } images={ this.props.datas.images }
                                            isDragging={ this.state.isDragging } fonts={ this.props.fonts } />
                        </div>
                    ))}
                </div>
            );
        }
    }
}

export default CenterMode;
